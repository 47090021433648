import { graphql, Link } from 'gatsby';
import * as React from 'react';
import MainLayout from '../layouts/MainLayout';
import { PageContext } from './post';
import Helmet from 'react-helmet';
import * as main from '../layouts/MainLayout.css';
import config from '../config';
import ArticlePoster from '../components/ArticlePoster';

interface AuthorTemplateProps {
  pathContext: {
    slug: string;
  };
  pageContext: {
    author: string;
  };
  data: {
    logo: {
      childImageSharp: {
        fixed: any;
      };
    };
    authorYaml: {
      id: string;
      website?: string;
      twitter?: string;
      facebook?: string;
      location?: string;
      profile_image?: {
        childImageSharp: {
          fluid: any;
        };
      };
      bio?: string;
      avatar: {
        childImageSharp: {
          fluid: any;
        };
      };
    };
    allMarkdownRemark: {
      totalCount: number;
      edges: {
        node: PageContext;
      }[];
    };
  };
}

const AuthorTemplate: React.FunctionComponent<AuthorTemplateProps> = (props) => {
  const author = props.data.authorYaml;
  const { edges, totalCount } = props.data.allMarkdownRemark;
  const twitter = config.twitter ? config.twitter.split('https://twitter.com/')[0] : "";
  return (
    <MainLayout>
      <Helmet>
        <title>{author.id} - {config.title}</title>
        <link rel="shortcut icon" href={props.data.logo.childImageSharp.fixed.src} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="profile" />
        <meta property="og:title" content={`${author.id} - ${config.title}`} />
        <meta property="og:url" content={config.siteUrl + props.pathContext.slug} />
        <meta property="article:publisher" content="https://www.facebook.com/nezaboodka" />
        <meta property="article:author" content="https://www.facebook.com/nezaboodka" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={`${author.id} - ${config.title}`} />
        <meta name="twitter:url" content={config.siteUrl + props.pathContext.slug} />
        <meta name="twitter:site" content={`@${twitter}`} />
        <meta name="twitter:creator" content={`@${twitter}`} />
      </Helmet>
      <div className={main.main}>Under construction {edges.length} {totalCount}</div>
      <div className={main.also}>
        <div className={main.heading}>AUTHOR POSTS</div>
        {props.data.allMarkdownRemark.edges.filter(x => x.node.frontmatter.author.id === author.id).map(p => {
          return (
            <Link className={"post-card-image-link"} to={p.node.fields.slug}>
              <ArticlePoster post={p.node}/>
            </Link>
          );
        })}
      </div>
    </MainLayout>
  );
};

export default AuthorTemplate;

export const pageQuery = graphql`
  query($author: String) {
    logo: file(relativePath: { eq: "img/nezaboodka.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    authorYaml(id: { eq: $author }) {
      id
      website
      twitter
      bio
      facebook
      location
      profile_image {
        childImageSharp {
          fluid(maxWidth: 3720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      avatar {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { draft: { ne: true } } },
      sort: { fields: [frontmatter___date], order: DESC },
      limit: 2000
    ) {
      totalCount
      edges {
        node {
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            date
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fixed(quality: 100) {
                      src
                    }
                  }
                }
              }
            }
          }
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;
